import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { Helmet } from "react-helmet";
import { sk } from './globals.js';
import { Main } from './main.js';
import { Footer, LoadLanguage, MenuLogo, MenuTop, useScrollDirection } from './items';
import { styles } from './styles.js';
import { Services } from './services.js';
import { Contact } from './contact.js';
import Fade from '@mui/material/Fade';
import { ParallaxProvider } from 'react-scroll-parallax';

function App() {
    const [language, setLanguage] = useState(0);
    const [showBanner, setShowBanner] = useState(true);
    const [index, setIndex] = useState(-1);

    const scrollDirection = useScrollDirection();

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const logo = require('./react/app/logo_white.png');

    var lang = sk;

    useEffect(() => {
        setLanguage(LoadLanguage());
    }, [])

    const MenuTopPress = (value) => {
    }

    const MenuLogoPress = (value) => {
        setIndex(value);
    }

    const MainPress = (value, id) => {
        setShowBanner(value);
        setIndex(id);
    }

    const QRCodePress = (value) => {
        setShowBanner(value);
    }

    return (
        <ParallaxProvider>
            <div className="App" style={{ backgroundColor: global.theme_white }}>
                <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
                />
                <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap" rel="stylesheet"></link>
                <Helmet>
                    <title>{global.title}</title>
                    <meta name="description" content="Chata nad králikamiu" />
                </Helmet>

                <Fade in={scrollDirection == 'up'} timeout={300}>
                    <div style={{ ...styles.Block, position: 'fixed', top: 0, left: 0, zIndex: 1000 }}>
                        <MenuTop func={MenuTopPress.bind(this)} />
                    </div>
                </Fade>

                <div style={{ marginTop: 36 }}>
                    <MenuLogo index={index} lang={lang} show_banner={showBanner} func={MenuLogoPress.bind(this)} />
                </div>
                <Routes>
                    <Route path="/" element={<Main func={MainPress.bind(this)} />} />
                    {/*
                    <Route path="/services" element={<Services func={MainPress.bind(this)} />} />
                    <Route path="/contact" element={<Contact func={MainPress.bind(this)} />} />
                    */}
                    <Route path="*" element={<Main func={MainPress.bind(this)} />} />
                </Routes>

                {/*
                <Footer />

                <div style={{ ...styles.BlockCenter, height: '100%', position: 'fixed', left: 0, top: 0, backgroundColor: global.theme_black, zIndex: 0 }}>
                    <div style={{ ...styles.Block, height: '50%' }}>
                        <div style={{ ...styles.Block }}>
                            <img src={logo} style={{ height: 100 }}></img>
                            <div style={{ ...styles.BlockRaw, height: 100, alignItems: 'center', justifyContent: 'center' }}>
                                <p style={{ ...styles.TextLabel, color: global.theme_white, fontWeight: 300, marginLeft: 20 }}>{lang.banner_label.toUpperCase()}</p>
                            </div>
                        </div>
                    </div>
                </div>
                */}

            </div>
        </ParallaxProvider>
    );
}

export default App;
