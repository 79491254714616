// **********************************************************************

global.testing = false;

// **********************************************************************

global.debugging = global.testing;
global.version = '1.0';
global.items_max = 25; // pagination - max.items na stránke
global.title = 'Chata nad Králikami';
global.creator = 'Datadream s.r.o.';

// **********************************************************************
// DATABAZA - LINK
// **********************************************************************
global.web = 'https://www.chatanadkralikami.eu';
global.db_url = global.web + '/app_web/';
global.images = global.web + '/public/images/';
global.pdf = global.web + '/public/pdf/';
global.www = 'www.chatanadkralikami.eu';
global.gallery = global.web + '/public/images/gallery/';
global.mats = global.web + '/public/images/mats/';

// **********************************************************************
// COLORS
// **********************************************************************
global.theme_back = '#F4F5FA';
global.theme_white = '#ffffff';
global.theme_black = '#000000';
global.theme_black_opacity = '#000000bb';
global.theme_light = '#FFC586';
global.theme_medium = '#C7945D';
global.theme_light_blue = '#44AEFF';
global.theme_lighter_blue = '#92D2FF';
global.theme_light_red = '#FFDDDD';
global.theme_light_green = '#C0E0B8';
global.theme_lighter = '#EEE7DF';
global.theme_dark = '#866037';
global.theme_darker = '#624A2F';
global.theme_dark_blue = '#003C6A';
global.theme_dark_red = '#F44336';
global.theme_dark_green = '#4D7B4D';
global.theme_blue = '#0061AD';
global.theme_red = '#F25751';
global.theme_green = '#76C73B';
global.theme_yellow = '#F4B549';
global.theme_badge = '#A12E28';
global.theme_placeholder = '#cccccc';
global.theme_light_violet = '#FFAAFF';
global.theme_dark_violet = '#84244D';
global.theme_none = '#00000000';

global.theme_gray = '#eeeeee';
global.theme_xxgray = '#aaaaaa';
global.theme_dark_gray = '#666666';
global.theme_medium_gray = '#888888';
global.theme_light_gray = '#dedede';
global.theme_menu_selected = '#FFFFFF';
global.theme_menu_background_selected = '#FFFFFF';
global.theme_submenu = '#aaaaaa';
global.theme_categorymenu = '#ffffff';

global.theme_selected = '#4D617B';
global.theme_orange = 'orange';
global.theme_violet = 'violet';
global.theme_yellow = 'yellow';
global.theme_brown = 'brown';

global.theme_footer = '#222222';
global.theme_footer_text = '#AAAAAA';
global.theme_footer_text_high = '#CCCCCC';

global.theme_menu = '#FFFFFF22';

global.theme_gradient = 'linear-gradient(to right,#FFFFFF77,#000000AA)';
global.theme_gradient_small = 'linear-gradient(to right,#FFFFFF22,#FFFFFFFF)';
global.theme_gradient_dark = 'linear-gradient(#7B614D,#DAC7B8)';
global.theme_menu_top_gradient = 'linear-gradient(to right,#E7DAD0,#EEEEEE)';
global.theme_gradient_dark_medium = 'linear-gradient(to right,#7B614D,#B29B89)';
global.theme_gradient_dark_dark = 'linear-gradient(to right,#7B614D,#7B614D)';
global.theme_gradient_gray_gray = 'linear-gradient(to right,#DDDDDD,#EFEFEF)';
global.theme_gradient_gray = 'linear-gradient(to right,#eeeeee,#eeeeee)';

// **********************************************************************
// DISPLAY SIZE
// **********************************************************************
global.device_small = 600;
global.device_medium = 992;
global.screen_max = 950;

// **********************************************************************
// FONT SIZE
// **********************************************************************
global.font_xxtiny = 12;
global.font_tiny = 14;
global.font_small = 16;
global.font_normal = 18;
global.font_large = 20;
global.font_xlarge = 24;
global.font_xxlarge = 28;
global.font_xxxlarge = 34;
global.font_label = 28;
global.font_dialog_label = 24; // dialogove boxy - label
global.font_menu = 18; // menu - lava strana
global.font_textlabel = 13; // label pre TextField <p></p>
global.font_categorymenu = 14;
global.font_products_grid = 16;
global.font_banner_label = 36;
global.font_banner_text = 18;
global.font_banner_label_small = 18;
global.font_banner_text_small = 14;
global.font_prices = 22;

// **********************************************************************
// RADIUS
// **********************************************************************
global.radius = 5;
global.padding = 40;
global.padding_small = 10;


// **********************************************************************
// COMPANY - UDAJE O SPOLOCNOSTI
// **********************************************************************
global.company = {
    call: '0911 - 661 213',
    name: 'Chata nad Králikami',
    street: '',
    psc: '',
    town: '',
    state: 'Slovensko',
    mobil1: '0911 - 661 213',
    mobil2: '0919 - 077 555',
    email: 'info@chatanadkralikami.eu',
    ico: '',
    dic: '',
    ic_dph: '',
    district: '',
}



// **********************************************************************
// SK - LANGUAGE
// **********************************************************************
export const sk = {
    title: 'Chata nad Králikami',
    slogan: 'Realizujeme Vaše predstavy - sny sa stávajú skutočnosťou',
    slogan: 'Kde sny sa stávajú skutočnosťou',
    slogan_description: 'Komplexné softwarové riešenia na mieru pre Vás a Vašu firmu',
    banner_label: 'Softwarové riešenia',

    home: 'Domov',
    years_business: 'Už 16 rokov na trhu',
    required_data: 'Údaje označené * sú povinné',

    months: ['Január', 'Február', 'Marec', 'Apríl', 'Máj', 'Jún', 'Júl', 'August', 'September', 'Október', 'November', 'December'],
    months_short: ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'Máj.', 'Jún.', 'Júl.', 'Aug.', 'Sep.', 'Okt.', 'Nov.', 'Dec.'],
    days: ['Nedeľa', 'Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota'],
    days_short: ['Ned.', 'Pon.', 'Uto.', 'Str.', 'Štv.', 'Pia.', 'Sob.'],
    today: 'Dnes',

    name: 'Meno',
    surname: 'Priezvisko',
    name_surname: 'Meno a priezvisko',
    street: 'Ulica',
    street_number: 'číslo',
    psc: 'PSČ',
    town: 'Mesto',
    email: 'E-mail',
    email_error: 'Nesprávny formát e-mailovej adresy',
    mobil: 'Mobil',
    phone: 'Tel.číslo',
    state: 'Štát',
    logo: 'Logo',
    ico: 'IČO',
    dic: 'DIČ',
    ic_dph: 'IČ-DPH',
    DPH: 'DPH',
    error: 'Chyba',

    continue: 'Pokračovať',
    back: 'Späť',
    close: 'Zavrieť',

    required: 'Povinný údaj',
    number: 'Číslo',
    active: 'Aktívny',
    label: 'Názov',
    db_error: 'Pri zápise nastala chyba. Skúste požiadavku opakovať',

    new: 'Nový',
    add: 'Pridať',
    ok: 'OK',
    cancel: 'Zrušiť',
    save: 'Uložiť',
    save_changes: 'Uložiť zmeny',
    edit: 'Upraviť',
    search: 'Hľadať',
    send: 'Odoslať',
    send_request: 'Odoslať požiadavku',
    saving: '...',
    subscribe: 'Odoberať',

    call_us: 'Volajte',

    gdpr: 'Ochrana osobných údajov',
    cookies_rules: 'Zásady používania súborov cookies',
    sell_docs: 'Všeobecné obchodné podmienky',

    about_us: 'O nás',
    products: 'Produkty',
    products_text: 'Výroba a predaj rohoží',
    gallery: 'Galéria',
    gallery_text: 'Galéria fotografií',
    reference: 'Referencie',
    reference_text: 'Naši klienti',
    contact: 'Kontakt',
    contact_text: 'Kontaktujte nás',
    contact_data: 'Kontaktné údaje',
    services: 'Služby',
    our_services: 'Čo ponúkame',
    more_info: 'Viac informácii',
    write: 'Napište nám',

    goto_app: 'Prejsť do aplikácie',
    demo: 'Vyskúšať zadarmo',

    company_name: 'Obchodný názov',
    company_street: 'Ulica',
    company_town: 'Ulica',
    company_psc: 'PSČ',

    captcha_error: 'Chýba potvrdenie - nie som robot',
    send_error: 'Pri odosielaní požiadavky nastala chyba. Skúste požiadavku opakovať',
    back_to_home: 'Späť na hlavnú stránku',
    order: 'Objednávka',
    order_text: 'Zakúpenie licencie',

    contact_form: 'Kontaktný formulár',
    message_question: 'Chcete sa na niečo opýtať? Napíšte nám.',
    message_text: 'Text správy',
    message_send_od: 'Správa bola úspešne odoslaná',

    service_1: 'Programovanie',
    service_2: 'Dizajn a logá',
    service_3: 'Grafika',
}

